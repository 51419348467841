
    import axios from 'axios'
    import store from '../store/index.js'

    export default {
        async add_replies(params)  {
            return await axios.post('replies/create.php' , params)
            .then(r =>{
                return r
            })
            .catch(e =>{
                console.log(e);
            })
        },
        async get_replies()  {
            // return await axios.get('replies/read.php')
            // .then(r =>{
            //     return r
            // })
            // .catch(e =>{
            //     console.log(e);
            // })
            store.commit('get_replies')
        },
        async delete_replies(reply_id)  {
            return await axios.post('replies/delete.php' , {
                reply_id : reply_id
            })
            .then(r =>{
                return r
            })
            .catch(e =>{
                console.log(e);
            })
        },
        async getOne(reply_id)  {
            return await axios.post('replies/readOne.php' , {
                reply_id : reply_id
            })
            .then(r =>{
                return r
                
            })
            .catch(e =>{
                console.log(e);
            })
        },
        async update_replies(params)  {
            return await axios.post('replies/update.php' , params)
            .then(r =>{
                return r
            })
            .catch(e =>{
                console.log(e);
            })
        },
        async report_replies(params)  {
            return await axios.post('replies/report.php',params)
            .then(r =>{
                return r
            })
            .catch(e =>{
                console.log(e);
            })
        },
    }
