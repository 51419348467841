
    <template>
    <div>
    <v-progress-linear :indeterminate="true" v-if="loading"></v-progress-linear>
        <v-container v-else>
            <form @submit.prevent="add_replies" autocomplete="off">
                <v-layout row wrap>
            <v-flex xs12 lg2 xl2 md3 sm4>
            <v-select class="mx-1" clearable rounded filled :items="comments" v-model="replies.comment_id" outlined  item-text="comment_id"
                            item-value="comment_id" :return-object="false" :label="$store.getters.lang.data.comment_id">
                        </v-select>
                        </v-flex>
            
        <v-flex xs12 lg2 xl2 md3 sm4>
        <v-text-field v-model="replies.reply_date" type="date" :label="$store.getters.lang.data.reply_date" outlined
            class="mx-1"  required rounded>
        </v-text-field>
    </v-flex>


        <v-flex xs12 lg2 xl2 md3 sm4>
        <v-text-field v-model="replies.reply_text" type="text" :label="$store.getters.lang.data.reply_text" outlined
            class="mx-1"  required rounded>
        </v-text-field>
    </v-flex>


            <v-flex xs12 lg2 xl2 md3 sm4>
            <v-select class="mx-1" clearable rounded filled :items="users" v-model="replies.user_id" outlined  item-text="user_id"
                            item-value="user_id" :return-object="false" :label="$store.getters.lang.data.user_id">
                        </v-select>
                        </v-flex>
            
    <v-flex xs12 lg2 xl2 md2 sm4>
                        <v-btn rounded color="primary" type="submit" x-large >{{$store.getters.lang.data.add_btn}} replies</v-btn>
                    </v-flex>
                </v-layout>
            </form>
            <v-layout row wrap mt-5>
                <v-flex xs12>
                    <v-card>
                        <v-card-title primary-title>
                        {{$store.getters.lang.data.replies}}
                            <v-spacer></v-spacer>
                            <v-text-field autocomplete="off" v-model="search" append-icon="fas fa-search">
                            </v-text-field>
                        </v-card-title>
                        <v-card-text>
                            <v-data-table :headers="headers" :search="search" :items="rows" class="elevation-0"
                                item-key="reply_id">
                                <template v-slot:[`item.reply_id`]="{ item }">
                                    <div>
                                        <v-btn icon color="error" :disabled="$store.state.auth.user.admin_role != 'admin'" class="mx-1" @click="select_replies(item)" rounded>
                                            <v-icon>mdi-delete-outline</v-icon>
                                        </v-btn>
                                        <v-btn :to="'/replies-list/'+item.reply_id" icon color="secondary" class="mx-1"
                                            rounded>
                                            <v-icon>mdi-pencil-outline</v-icon>
                                        </v-btn>
                                    </div>
                                </template>
                            </v-data-table>
                        </v-card-text>
                    </v-card>
                </v-flex>
            </v-layout>
        </v-container>
        <v-dialog v-model="delete_dialog" persistent max-width="290">
            <v-card>
                <v-card-title>
                    {{$store.getters.lang.data.delete_question}}
                </v-card-title>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="error" class="elevation-0" @click="delete_dialog = false">
                    {{$store.getters.lang.data.cancel_btn}}
                    </v-btn>
                    <v-btn color="green darken-1" text @click="delete_replies(selected_replies.reply_id)">
                    {{$store.getters.lang.data.yes_btn}}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-snackbar v-model="snackbar.value" :color="snackbar.color">
            {{snackbar.text}}
        </v-snackbar>
    </div>
</template>
<script>
    import controller from './../../controller/replies'
    export default {
        data() {
            return {
                replies: {},
                search: '',
                loading : true,
                snackbar: {
                    value: false,
                    text: '',
                    color: ''
                },
                selected_replies: {},
                delete_dialog: false,
                headers: [
                    
        {
            text: this.$store.getters.lang.data.reply_id,
            align: 'start',
            sortable: true,
            value: 'reply_id',
        },


        {
            text: this.$store.getters.lang.data.comment_id,
            align: 'start',
            sortable: true,
            value: 'comment_id',
        },


        {
            text: this.$store.getters.lang.data.reply_date,
            align: 'start',
            sortable: true,
            value: 'reply_date',
        },


        {
            text: this.$store.getters.lang.data.reply_text,
            align: 'start',
            sortable: true,
            value: 'reply_text',
        },


        {
            text: this.$store.getters.lang.data.user_id,
            align: 'start',
            sortable: true,
            value: 'user_id',
        },


                ],
            }
        },
        computed: {
            rows(){
                return this.$store.getters.replies
            },
            
                    comments (){
                        return this.$store.getters.comments
                    },
                    
                    users (){
                        return this.$store.getters.users
                    },
                    
            
        },
        mounted(){
            this.read_replies();
        },
        methods: {
            add_replies() {
                controller.add_replies(this.replies).then(r => {
                    if (r.data) {
                        this.replies = {}
                        this.$store.state.replies.push(
                            r.data.new_data
                        )
                        this.snackbar = {
                            value: true,
                            text: 'replies Added',
                            color: 'success'
                        }
                    } else {
                        this.snackbar = {
                            value: true,
                            text: 'Fail to add replies',
                            color: 'error'
                        }
                    }
                })
            },
            delete_replies(reply_id) {
                controller.delete_replies(reply_id).then(r => {
                    this.delete_dialog = false
                    if (r.data) {
                        this.$store.state.replies = this.$store.state.replies.filter(f =>{
                            return f.reply_id != reply_id
                        })
                        this.snackbar = {
                            value: true,
                            text: 'replies Deleted',
                            color: 'success'
                        }
                    } else {
                        this.snackbar = {
                            value: true,
                            text: 'Delete Faild',
                            color: 'error'
                        }
                    }
                })
            },
            read_replies() {
                this.loading = true
                controller.get_replies().then(r => {
                    // this.rows = r.data.rows
                    this.loading = false
                })
            },
            select_replies(i) {
                this.selected_replies = i
                this.delete_dialog = true
            },
        },
    }
</script>
